import { Injectable } from '@angular/core';

import { AwareSecurityService } from '@appbolaget/aware-security';
import { take } from 'rxjs/operators';

@Injectable()
export class GodGuard {
    constructor(private awareSecurityService: AwareSecurityService) {}

    canActivate() {
        return this.awareSecurityService.isGod().pipe(take(1));
    }
}
